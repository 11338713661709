.bg-primary,
.btn-primary,
.btn-outline-primary:hover, 
.btn-outline-primary:focus, 
.btn-outline-primary:active, 
.btn-outline-primary.active, 
.btn-outline-primary.focus, 
.btn-outline-primary:not(:disabled):not(.disabled):active,
.badge-primary,
.nav-pills .nav-link.active,
.pagination .active a,
.custom-control-input:checked ~ .custom-control-label:before,
#preloader #status .spinner > div,
.social-icon li a:hover,
.back-to-top:hover,
.back-to-home a,
::selection,
#topnav .navbar-toggle.open span:hover,
.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots.clickable .owl-dot:hover span,
.watch-video a .play-icon-circle,
.sidebar .widget .tagcloud > a:hover,
.flatpickr-day.selected, .flatpickr-day.selected:hover,
.container-filter li a:hover, .container-filter li a.active,
.tns-nav button.tns-nav-active,
.form-check-input.form-check-input:checked {
    background-color: #6dc77a !important;
}

.text-primary,
.btn-outline-primary,
.alert-outline-primary,
.badge-outline-primary,
.page-next-level .page-next li a:hover,
.faq-content .card a.faq[data-toggle=collapse],
.faq-content .card a.faq[data-toggle=collapse]:before,
.pagination li a:hover,
#topnav .has-submenu.active .submenu li.active > a,
#topnav .navigation-menu > li > a:hover, 
#topnav .navigation-menu > li > a:active,
#topnav .navigation-menu > li:hover > a,
#topnav .navigation-menu > li.active > a,
#topnav.scroll .navigation-menu > li:hover > a, 
#topnav.scroll .navigation-menu > li.active > a,
#topnav .navigation-menu > li > a:hover,
#topnav .navigation-menu > li .submenu li a:hover,
#topnav .navigation-menu > li.has-submenu.open > a,
#topnav .has-submenu.active a,
.catagories .title:hover, 
.cases .title:hover, 
.branches .title:hover,
.feature:hover .border-icon,
.work-container .content .title:hover,
.blog-post .blog-img .post-meta .read-more:hover,
.blog-post .content .title:hover,
.blog-post .content .author .name:hover,
.sidebar .widget .catagory li a:hover,
.sidebar .widget .post-recent .post-recent-content a:hover,
.branches .content .details .phone:hover,
.branches .content .details .mail:hover, 
.branches .content .details .googlemap:hover,
.footer .footer-list li a:hover,
.accordion .accordion-item .accordion-button:not(.collapsed),
.accordion .accordion-item .accordion-button:before {
    color: #6dc77a !important;
}

.btn-primary,
.btn-outline-primary,
.btn-outline-primary:hover, 
.btn-outline-primary:focus, 
.btn-outline-primary:active, 
.btn-outline-primary.active, 
.btn-outline-primary.focus, 
.btn-outline-primary:not(:disabled):not(.disabled):active,
.alert-primary,
.alert-outline-primary,
.badge-outline-primary,
.nav-pills .nav-link.active,
.pagination .active a,
.form-group .form-control:focus, 
.form-group .form-control.active,
.custom-control-input:checked ~ .custom-control-label:before,
.custom-control-input:focus ~ .custom-control-label::before,
.form-control:focus,
.social-icon li a:hover,
#topnav .has-submenu.active.active .menu-arrow,
#topnav.scroll .navigation-menu > li:hover > .menu-arrow, 
#topnav.scroll .navigation-menu > li.active > .menu-arrow,
#topnav .navigation-menu > li:hover > .menu-arrow,
.flatpickr-day.selected, 
.flatpickr-day.selected:hover,
.form-check-input:focus,
.form-check-input.form-check-input:checked {
    border-color: #6dc77a !important;
}

a.text-primary:hover, a.text-primary:focus {
    color: #48b958 !important;
}

.btn-primary:hover, 
.btn-primary:focus, 
.btn-primary:active, 
.btn-primary.active, 
.btn-primary.focus {
    background-color: #48b958 !important;
    border-color: #48b958 !important;
}

.alert-primary {
    background-color: #66c473;
}

.alert-primary .alert-link {
    color: #2b7035;
}

.btn-primary,
.btn-outline-primary:hover, 
.btn-outline-primary:focus, 
.btn-outline-primary:active, 
.btn-outline-primary.active, 
.btn-outline-primary.focus, 
.btn-outline-primary:not(:disabled):not(.disabled):active {
    box-shadow: 0 3px 7px rgba(109, 199, 122, 0.5) !important;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before,
.tns-nav button {
    background-color: rgba(109, 199, 122, 0.3) !important;
    border-color: rgba(109, 199, 122, 0.3) !important;
}

.bg-overlay-primary {
    background-image: linear-gradient(135deg, #6dc77a, #6dc77a, #6dc77a);
}

.feature .border-icon {
    color: #7fce8b;
}

.owl-theme .owl-dots .owl-dot span {
    background: #92d59c !important;
}

.play-icon i {
    -webkit-text-stroke: 2px #6dc77a;
    -webkit-text-fill-color: transparent;
}
  
.play-icon i:hover {
    -webkit-text-stroke: 2px #6dc77a;
    -webkit-text-fill-color: #6dc77a;
}